import React from 'react';
import Markdown from 'react-markdown';

export default function Header() {
  // Function to open the modal
  const openModal = () => {
    const modal = document.getElementById('premium_modal') as HTMLDialogElement;
    if (modal) {
      modal.showModal();
    }
  };

  const premiumText = `
## Dein eigener RAG Chatbot von enki!
- 🎓 Chate mit deinen Daten - Egal ob Webseiten, Handbücher oder sogar Jira Tickets.
- 🔄 Freie Wahl des LLM - Ob OpenAI oder Llama, ob geteilt oder dediziert, alles geht.
- 🌐 Als Service oder On-Prem - Flexibilität nach deinen Bedürfnissen.
- 📚 Chatverlauf - Behalte den Überblick über alle Konversationen.
- 📈 Statistiken - Analysiere die Performance deines Chatbots.
- 🎨 Custom UI - Gestalte die Benutzeroberfläche nach deinen Vorstellungen.
- 🔐 Authentifizierung und Autorisierung - Sichere deinen Chatbot mit SSO ab.
`;

  return (
    <>
      <dialog id="premium_modal" className="modal">
        <div className="modal-box w-auto max-w-3xl">
          <Markdown className="prose-base">{premiumText}</Markdown>
          <div className="flex justify-end mt-4"> {/* Added some top margin for the button */}
            <a href="mailto:info@enki.swiss?subject=Interesse an persöhnlichem Chatbot" className="btn bg-purple-500 text-white"> {/* Larger button text */}
              Kontakt
            </a>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      <div className="navbar flex-none bg-base-100 justify-between">
        <a className="btn btn-ghost text-xl hover:bg-base-100" href='/'>
          <img src="/logo.png" alt="Logo" className="mr-2 h-6" />
          <p className='hidden md:block'>LozärnGPT Beta</p>
        </a>
        <div className="flex items-center">
          <button onClick={openModal} className="btn bg-purple-500 hover:bg-purple-300 text-white mr-2 sm:text-sm">
            Das will ich auch
          </button>
          <button onClick={() => window.location.reload()} className="btn bg-purple-500 hover:bg-purple-300 text-white mr-2 sm:text-sm">
            Neuer Chat
          </button>
        </div>
      </div>
    </>
  );
}