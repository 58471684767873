import React, { useState, KeyboardEvent } from 'react';
import { parse } from 'best-effort-json-parser'
import TextareaAutosize from 'react-textarea-autosize';
import Markdown from 'react-markdown';

import { useRag } from '../context/RagContext';

const examples = [
	"Ich möchte ins Gymnasium, wie funktioniert das?",
	"Was macht die Dienststelle Raum und Wirtschaft?",
	"Wer darf eine Petition einreichen?",
	"Was macht der Kantonsratspräsident?",
]

//const examples = [
//	"What is Hexagon OnCall?",
//	"How do I create an event?",
//	"How do I create an event from command line?",
//	"What happens when dispatching a unit?",
//]


const defaultGreeting = `Herzlich willkommen bei *LozärnGPT*! 👋  \n
Ich bin Ihr intelligenter digitaler Assistent, entwickelt mit modernster KI-Technologie. Basierend auf einem fortschrittlichen RAG-System (Retrieval-Augmented Generation), habe ich Zugriff auf sämtliche öffentlichen Informationen des Kantons Luzern. Das bedeutet für Sie:  \n
\n
- 🏛️ Umfassendes Wissen zur Kantonsorganisation
- 📄 Detaillierte Auskunft zu Behördengängen
- 🏔️ Aktuelle Infos zu Tourismusangeboten
- 🎓 Einblicke in Bildung und Kultur
- 💼 Informationen zu Wirtschaft und Arbeit
\n
Ob Sie eine einfache Frage haben oder komplexe Zusammenhänge verstehen möchten - ich bin hier, um Ihnen präzise und hilfreiche Antworten zu geben.  \n
Wie kann ich Ihnen heute behilflich sein? 🌍🇨🇭`;
//const defaultGreeting = "Wie kann ich dir helfen? Ich weiss eine Menge über Luzern! 🌍🇨🇭";
//const defaultGreeting = "Ich bin im Wochenende-Modus. 😴 Ab Montag 8 Uhr bin ich wieder verfügbar.";
//const defaultGreeting = "Ich bin im Moment offline. 😔 Wahrscheinlich um Kosten zu sparen... Da ich vollständig auf externe Services verzichte, bin ich nicht ganz billig (dafür sehr schnell und komplett privat) 😉. Falls du mich in Aktion sehen oder mehr über mich erfahren willst, schreibe an info@enki.swiss."
//const defaultGreeting = "How can I help you? I know a lot about Hexagon OnCall!";
//const errorGreeting = "Im Moment bin ich leider wegen Wartungsarbeiten offline. 😔";

export default function Chat() {

	interface IMessage {
		content: string;
		role: 'user' | 'bot';
	}

	const [messageHistory, setMessageHistory] = useState<IMessage[]>([{
		content: defaultGreeting,
		role: 'bot'
	}]);
	const [userMessage, setUserMessage] = useState('');
	const [botMessage, setBotMessage] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [showExamples, setShowExamples] = useState(true);

	const [conversationId, setConversationId] = useState<string | undefined>(undefined);

	const { documents, addDocument } = useRag();

	const sendMessage = async (message: string) => {
		if (isLoading || message === "") return;
		setIsLoading(true);
		setShowExamples(false);

		setMessageHistory(prevMessages => [...prevMessages, { content: message, role: 'user' }]);

		setBotMessage(undefined);
		setUserMessage('');

		let tempMessageHistory: IMessage[] = [];

		if (messageHistory.length > 1) {
			tempMessageHistory = messageHistory;
		}

		try {
			const response = await fetch("https://rag-server.enki.farm/query", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ "query": message, "k": 5, history: tempMessageHistory, conversation_id: conversationId }),
			});
			if (!response.ok || !response.body) {
				throw response.statusText;
			}

			// Here we start prepping for the streaming response
			const reader = response.body.getReader();
			const decoder = new TextDecoder();

			setIsLoading(false);

			let result: any = undefined;
			let isFirst = true;
			
			while (true) {
				
				const { value, done } = await reader.read();
				
				const decodedChunk = decoder.decode(value, { stream: true });
				if (decodedChunk !== "") {
					if (isFirst) {
						isFirst = false;
						// get the first json object
						result = parse(decodedChunk);

						if (result['relevant_document'] !== undefined && documents.length === 0) {
							result.relevant_document.forEach((d: any) => {
								addDocument({
								  id: d.document_id,
								  passage_id: d.passage_id,
								  score: d.score,
								  url: d.document_metadata.url,
								  content: d.content
								});
							  });
						}

						if (result['conversation_id'] !== undefined) {
							setConversationId(result['conversation_id']);
						}
					}

					// split the chunk
					let split = decodedChunk.split("}");
					// get last entry
					let last = split[split.length - 2];
					// add the missing bracket
					last += "}";
					result = parse(last);
				}

				if (done) {
					// eslint-disable-next-line no-loop-func
					setMessageHistory(prevMessages => [...prevMessages, { content: result.response, role: 'bot'}]);
					setBotMessage(undefined);
					break;
				}
				setBotMessage(result['response']);
			}
		} catch (error) {
			console.error(error);
		}
	}

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			sendMessage(userMessage);
		}
	}

	return (
		<div className="flex flex-col h-full">
			<div id="chat-window" className="grow overflow-y-auto">
				{messageHistory.map((message, index) => (
					<div key={index} className={`chat ${message.role === 'user' ? 'chat-end' : 'chat-start'}`}>
						<div className={`chat-bubble ${message.role === 'bot' ? 'bg-blue-200 text-black' : 'bg-green-200 text-black'}`}>
							<Markdown className="prose-sm">{message.content}</Markdown>
						</div>
					</div>
				))}
				{isLoading && (
					<div className="chat chat-start">
						<div className="chat-bubble bg-blue-200 text-black">
							<span className="loading loading-ball loading-xs"></span>
						</div>
					</div>

				)}
				{botMessage !== undefined && (
					<div className="chat chat-start">
						<div className="chat-bubble bg-blue-200 text-black">
							<Markdown className="prose-sm">{botMessage}</Markdown>
						</div>
					</div>
				)}
			</div>
			{showExamples && (
				<div id="example-container">
					<div className="grid grid-cols-2 gap-2 h-1/5 mx-auto mt-4">
						{examples.map((example, index) => (
							<div key={index} className="border-2 border-purple-400 hover:bg-purple-100 p-2 rounded-lg" onClick={() => sendMessage(example)}>{example}</div>
						))}
					</div>
				</div>
			)}
			<div id="search" className="flex mt-4 border-2 border-purple-400 rounded-lg relative">
				<TextareaAutosize placeholder="Schreibe eine Nachricht..." className="textarea w-[94%]" value={userMessage} onChange={(e) => setUserMessage(e.target.value)} onKeyUp={handleKeyPress} />
				<button className="btn border-2 bg-purple-400 hover:bg-purple-300 text-white rounded-3xl absolute bottom-0 right-0" onClick={() => sendMessage(userMessage)}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-4 w-4">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
					</svg>
				</button>
			</div>
		</div>
	)
}